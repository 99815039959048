import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import Menu from '../components/Menu';
import { bpDesktopQuery } from '../libs/breakpoints';
import Footer from '../components/Footer';

interface PlaintextProps {
  textKey: string;
}

export default function Plaintext({ textKey }: PlaintextProps) {
  const [value, setValue] = useState('');

  useEffect(() => {
    fetch(
      `https://admin.the-jeals.com/api/content/item/texts?filter={key:"${textKey}"}`,
    )
      .then((res) => res.json())
      .then((data) => setValue(data.content));
  }, [textKey]);

  return (
    <>
      <StyledPlaintext>
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      </StyledPlaintext>

      <Footer />
      <Menu />
    </>
  );
}

const StyledPlaintext = styled.section`
  width: 90vw;
  min-height: 100vh;
  padding: 5vw;

  background-color: var(--bg-dark-0);
  color: var(--fg-light-0);

  font-family: var(--font-1);
  font-size: 4vw;
  font-weight: 200;
  font-style: normal;

  h1 {
    text-align: center;
    font-size: 7vw;
    font-weight: 600;
  }

  h2 {
    font-size: 6vw;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 5vh;
    text-align: justify;
  }

  li > p {
    margin-bottom: 0;
  }

  a {
    color: var(--fg-light-0);
  }

  @media ${bpDesktopQuery} {
    font-size: 2vh;
    width: 80vh;
    padding: 5vh calc((100vw - 80vh) / 2);

    h1 {
      font-size: 7vh;
    }
    h2 {
      font-size: 5vh;
    }
  }
`;
