import { useMediaQuery } from 'react-responsive';

interface BpProps {
  children: React.ReactNode;
}

export const bpMobile = '800px';
export const bpMobileQuery = `(max-width: ${bpMobile})`;
export const useMobileQuery = () => useMediaQuery({ query: bpMobileQuery });
export const Mobile = ({ children }: BpProps) => {
  const isMobile = useMobileQuery();
  return isMobile ? children : null;
};

export const useMobileLandscapeQuery = () =>
  useMediaQuery({
    query: `(max-width: ${bpMobile}) and (orientation: landscape)`,
  });

export const bpDesktop = '801px';
export const bpDesktopQuery = `(min-width: ${bpDesktop})`;
export const useDesktopQuery = () => useMediaQuery({ query: bpDesktopQuery });
export const Desktop = ({ children }: BpProps) => {
  const isDesktop = useDesktopQuery();
  return isDesktop ? children : null;
};
