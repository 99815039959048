import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import {
  Desktop,
  Mobile,
  bpDesktopQuery,
  bpMobileQuery,
} from '../libs/breakpoints';
import { SpacerDown, SpacerUp } from './Spacer';
import LoadCounter from '../libs/loadCounter';

interface VideosProps {
  loadCounter: LoadCounter;
}

interface Video {
  name: string;
  hash: string;
}

export default function Videos({ loadCounter }: VideosProps) {
  useEffect(() => {
    loadCounter.increaseCounter();
    return () => loadCounter.decreaseCounter();
  }, [loadCounter]);
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    if (videos.length > 0) {
      loadCounter.increaseLoaded();
    }
  }, [videos, loadCounter]);

  useEffect(() => {
    fetch(
      'https://admin.the-jeals.com/api/content/items/youtubeVideos?sort={_created:-1}',
    )
      .then((res) => res.json())
      .then((data) => {
        setVideos(data);
      });
  }, []);

  return (
    <StyledVideos id="videos">
      <Mobile>
        <h1>Neue Videos</h1>
      </Mobile>

      <Desktop>
        <video preload="none" autoPlay muted loop>
          <source src="img/performance.mp4" type="video/mp4"></source>
        </video>
      </Desktop>

      <div className="container">
        {videos.map((v) => (
          <iframe
            key={v.hash}
            src={`https://www.youtube.com/embed/${v.hash}`}
            title={v.name}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        ))}
      </div>

      <Mobile>
        <SpacerDown />
      </Mobile>
      <Mobile>
        <SpacerUp />
      </Mobile>
    </StyledVideos>
  );
}

const StyledVideos = styled.section`
  color: var(--fg-dark-0);
  font-family: var(--font-1);
  font-size: 4vw;
  font-weight: 200;
  font-style: normal;
  padding: 5vh;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;

  video {
    max-height: 50vh;
    border-radius: 0.5vh;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3vh;
  }

  .container iframe {
    aspect-ratio: 16/9;
    border: none;
  }

  h1 {
    text-align: center;
  }

  @media (min-aspect-ratio: 10/5) {
    flex-direction: row;
    gap: 0;
    align-items: center;
    video {
      margin-left: 6vh;
    }
  }

  @media ${bpDesktopQuery} {
    background-color: var(--bg-dark-0);

    .container iframe {
      width: 35vh;
      padding: 0.5vh;
      border: 0.15vh solid var(--fg-light-1);
      border-radius: 0.5vh;
    }
  }

  @media ${bpMobileQuery} {
    color: var(--fg-light-1);
    background: unset;
    background-color: #060012aa;
    backdrop-filter: blur(0.5vh);
    padding-bottom: 10vh;

    .container iframe {
      border-radius: 0.5vh;
      box-shadow: 0 0 0.5vh var(--bg-dark-0);
    }
  }
`;
