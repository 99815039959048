import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Desktop, Mobile, useMobileLandscapeQuery } from '../libs/breakpoints';

import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Instagram from '../components/Instagram';
import Tourdata from '../components/Tourdata';
import BandInfo from '../components/BandInfo';
import Videos from '../components/Videos';
import Booking from '../components/Booking';
import Menu from '../components/Menu';
import NewAlbum from '../components/NewAlbum';
import Welcome from '../components/Welcome';
import LoadCounter from '../libs/loadCounter';

export default function Main() {
  const { hash } = useLocation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const loadCounter = new LoadCounter(() => setLoaded(true));

  useEffect(() => {
    if (loaded) {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start' });
        }
      }

      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
      }
    }
  }, [loaded, hash]);

  window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty(
      '--scroll-y',
      `${window.scrollY}px`,
    );
  });

  window.onhashchange = () => {
    window.history.replaceState('', '', window.location.pathname);
  };
  window.history.replaceState('', '', window.location.pathname);

  if (useMobileLandscapeQuery()) {
    return <Landscape>Please view in portrait mode</Landscape>;
  }

  return (
    <>
      <Mobile>
        <Welcome />
      </Mobile>
      <Banner />

      <Desktop>
        <Instagram loadCounter={loadCounter} />
      </Desktop>

      <Mobile>
        <NewAlbum />
      </Mobile>

      <Tourdata loadCounter={loadCounter} />
      <BandInfo loadCounter={loadCounter} />
      <Videos loadCounter={loadCounter} />
      <Booking loadCounter={loadCounter} />

      <Desktop>
        <Maps
          className="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79526.1950764173!2d11.889973084336956!3d51.47296065549795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a66347fcfc709f%3A0x4236659f806e460!2sHalle%20(Saale)!5e0!3m2!1sde!2sde!4v1711189105875!5m2!1sde!2sde"
          title="maps"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Desktop>

      <Footer />

      <Menu />
    </>
  );
}

const Maps = styled.iframe`
  width: 100vw;
  height: 40vh;
  background-color: var(--bg-dark-0);
  border: none;
  filter: grayscale(1) invert(1);
`;

const Landscape = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-dark-0);
  color: var(--fg-light-0);
  font-family: var(--font-1);
  width: 100vw;
  height: 100vh;
`;
