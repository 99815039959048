import React, { useState } from 'react';
import { styled } from 'styled-components';

export default function Welcome() {
  const [value, setValue] = useState(true);

  setTimeout(() => {
    setValue(false);
  }, 3000);

  return value ? (
    <StyledWelcome>
      <div className="dark">
        <img src="img/welcome.webp" alt="the-jeals logo" />
      </div>
    </StyledWelcome>
  ) : null;
}

const StyledWelcome = styled.div`
  width: 100dvw;
  height: 100dvh;
  background-color: var(--fg-light-1);
  z-index: 100;
  position: fixed;
  animation: move-light 3s forwards;

  .dark {
    width: 100%;
    height: 100%;
    background-color: var(--bg-dark-0);
    animation: move-dark 3s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 40dvw;
    animation: rotate-img 3s forwards;
  }

  @keyframes move-dark {
    0% {
      transform: translateX(-100dvw);
    }
    5% {
      transform: translateX(-100dvw);
    }
    15% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes move-light {
    0% {
      transform: translateX(0);
    }
    85% {
      transform: translateX(0);
    }
    95% {
      transform: translateX(-100dvw);
    }
    100% {
      transform: translateX(-100dvw);
    }
  }
  @keyframes rotate-img {
    0% {
      transform: rotate(0deg) scale(0);
    }
    10% {
      transform: rotate(0deg) scale(0);
    }
    40% {
      transform: rotate(360deg) scale(1);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`;
