import React, { useEffect, useRef } from 'react';
import { styled } from 'styled-components';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function Modal({ open, onClose, children }: ModalProps) {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open) {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      const body = document.body;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;

      ref.current?.showModal();
    } else {
      ref.current?.close();

      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [open]);

  const backdropClick = (e: React.MouseEvent<HTMLDialogElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    if (
      rect.left > e.clientX ||
      rect.right < e.clientX ||
      rect.top > e.clientY ||
      rect.bottom < e.clientY
    ) {
      onClose();
    }
  };

  return (
    <StyledDialog ref={ref} onCancel={onClose} onClick={backdropClick}>
      {open ? children : null}
    </StyledDialog>
  );
}

const StyledDialog = styled.dialog`
  padding: 0;
  border: none;

  &::backdrop {
    background-color: #ffffff55;
    pointer-events: all;
  }
`;
