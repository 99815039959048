export default class LoadCounter {
  private counter: number;
  private loaded: number;
  private onLoaded: () => void;

  constructor(onLoaded: () => void) {
    this.counter = 0;
    this.loaded = 0;
    this.onLoaded = onLoaded;
  }

  increaseCounter() {
    this.counter++;
  }

  decreaseCounter() {
    this.counter--;
  }

  increaseLoaded() {
    this.loaded++;

    if (this.isLoaded()) {
      this.onLoaded();
    }
  }

  isLoaded() {
    return this.loaded >= this.counter;
  }
}
