import { styled } from 'styled-components';

export const SpacerUp = styled.div`
  width: 100%;
  background-image: url('img/spacer.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  height: 5vh;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -1px;
`;

export const SpacerDown = styled.div`
  width: 100%;
  background-image: url('img/spacer.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  height: 5vh;
  transform: scaleY(-1);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -1px;
`;
