import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Desktop, bpDesktopQuery } from '../libs/breakpoints';
import { SpacerDown, SpacerUp } from './Spacer';
import LoadCounter from '../libs/loadCounter';

interface BandInfoProps {
  loadCounter: LoadCounter;
}

export default function BandInfo({ loadCounter }: BandInfoProps) {
  useEffect(() => {
    loadCounter.increaseCounter();
    return () => loadCounter.decreaseCounter();
  }, [loadCounter]);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value !== '') {
      loadCounter.increaseLoaded();
    }
  }, [loadCounter, value]);

  useEffect(() => {
    fetch(
      'https://admin.the-jeals.com/api/content/item/texts?filter={key:"band-position"}',
    )
      .then((res) => res.json())
      .then((data) => {
        setValue(data.content);
      });
  }, []);

  return (
    <StyledBandInfo id="band-info">
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: value }}></div>

        <a href="/bandhistory" className="history">
          Hier geht&apos;s zur Bandgeschichte!
        </a>
      </div>

      <Desktop>
        <SpacerDown />
      </Desktop>
      <Desktop>
        <SpacerUp />
      </Desktop>
    </StyledBandInfo>
  );
}

const StyledBandInfo = styled.section`
  .content {
    width: 90vw;
    padding: 5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-dark-0);
    color: var(--fg-light-0);
    font-family: var(--font-1);
    font-size: 4vw;
    font-weight: 200;
    font-style: normal;
    text-align: justify;

    p {
      margin: 0;
      min-height: 1em;
    }
  }

  .history {
    color: var(--fg-light-0);
    text-decoration: none;
    border: 0.25vw solid var(--fg-light-0);
    border-radius: 1vw;
    padding: 2vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  @media ${bpDesktopQuery} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vh;
    position: relative;
    box-shadow: inset 0 0 10vh #000b;
    width: calc(100vw - 10vh);

    &::before {
      content: '';
      display: block;

      background-image: url('img/filler.webp');
      background-size: cover;
      background-position: center;
      background-attachment: fixed;

      /* filter: sepia(50%) brightness(0.7) hue-rotate(-30deg); */
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .content {
      width: 90vh;
      padding: 5vh;
      display: unset;
      background-color: var(--bg-dark-0);
      color: var(--fg-light-0);
      font-family: var(--font-1);
      font-size: 1.75vh;
      font-weight: 200;
      font-style: normal;
      border-radius: 5vh;
    }
    .content h1 {
      font-size: 2.5vh;
      text-align: left;
    }

    .history {
      color: var(--fg-light-0);
      text-decoration: none;
      border: 0.25vh solid var(--fg-light-0);
      border-radius: 0.25vh;
      padding: 2vh 5vh;
      margin-top: 2vh;
      margin-bottom: 2vh;
      display: inline-block;
      font-weight: 600;
    }
  }
`;
