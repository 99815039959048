import { styled } from 'styled-components';
import React, { useState } from 'react';
import { useMobileQuery } from '../libs/breakpoints';
import Socials from './Socials';

export default function Menu() {
  if (useMobileQuery()) {
    return <MobileMenu />;
  } else {
    return <DesktopMenu />;
  }
}

function MobileMenu() {
  const [open, setOpen] = useState(false);

  function toggleMenu() {
    if (open) {
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      const body = document.body;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    }
    setOpen(!open);
  }

  return (
    <StyledMobileMenu>
      <div className="icon" onClick={toggleMenu}>
        {!open ? '\u2630' : '\u26CC'}
      </div>

      <nav className={!open ? 'closed' : 'open'}>
        <a href="/#tourdata" onClick={toggleMenu}>
          Tourdaten
        </a>
        <a href="/#band-info" onClick={toggleMenu}>
          Dafür stehen wir
        </a>
        <a href="/#videos" onClick={toggleMenu}>
          Videos
        </a>
        <a href="/#booking" onClick={toggleMenu}>
          Booking
        </a>
        <Socials></Socials>
      </nav>
    </StyledMobileMenu>
  );
}

function DesktopMenu() {
  return (
    <StyledDesktopMenu>
      <nav>
        <a href="/#tourdata">Tourdaten</a>
        <a href="/#band-info">Dafür stehen wir</a>
        <a href="/#videos">Videos</a>
        <a href="/#booking">Booking</a>
      </nav>
    </StyledDesktopMenu>
  );
}

const StyledMobileMenu = styled.div`
  .icon {
    position: fixed;
    top: 5vw;
    right: 5vw;
    font-size: 7vw;
    cursor: pointer;
    z-index: 1;
    width: 10vw;
    height: 10vw;
    color: var(--fg-light-1);
    background-color: var(--bg-dark-0);
    text-align: center;
    border-radius: 1vw;
  }

  a {
    color: var(--fg-light-1);
    text-decoration: none;
    font-size: 7vw;
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-dark-0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    transition: transform 0.25s ease-in;
  }

  .closed {
    transform: translateX(100vw);
  }
`;

const StyledDesktopMenu = styled.div`
  position: fixed;
  top: 50%;
  background-color: var(--bg-dark-0);
  padding: 4vh;
  transform: translate(-90%, -50%) perspective(20vw) rotateY(10deg);
  transition: transform 0.25s;
  border: 0.1vh solid var(--dark-purple);
  z-index: 1;

  nav {
    display: flex;
    flex-direction: column;
    transform: perspective(20vw) rotateY(-5deg);
  }

  &:hover {
    transform: translate(0%, -50%) perspective(20vw) rotateY(10deg);
  }

  a {
    color: var(--fg-light-0);
    text-decoration: none;
    font-family: var(--font-1);
    font-weight: 600;
    font-style: normal;
    font-size: 2vh;
    margin: 1vh 0;
    transition: color ease-in 0.15s;
    text-transform: uppercase;
  }

  a:hover {
    color: var(--dark-purple);
  }
`;
