import { styled } from 'styled-components';
import React from 'react';
import { bpDesktopQuery, bpMobileQuery } from '../libs/breakpoints';

export default function Footer() {
  return (
    <Copyright>
      <span>&copy; 2025 The Jeals. All rights reserved.</span>
      <div>
        <a href="/imprint">Impressum</a>
        <a href="/privacy-policy">Datenschutzerkl&auml;rung</a>
      </div>
    </Copyright>
  );
}

const Copyright = styled.div`
  padding: 3vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 3.5vw;
    color: var(--fg-light-1);
    border-top: 0.1vh solid var(--fg-light-1);
    padding: 1vh 5vw 0 5vw;
  }

  a {
    font-size: 3.5vw;
    color: var(--fg-light-1);
    padding: 1vh 1vw 0 1vw;
    text-decoration: none;
  }

  @media ${bpDesktopQuery} {
    span {
      font-size: 1.25vh;
      padding: 1vh 5vw 0 5vw;
    }
    a {
      font-size: 1.25vh;
      padding: 1vh 1vw 0 1vw;
    }
  }

  @media ${bpMobileQuery} {
    background-color: var(--bg-dark-0);
  }
`;
